@use "sass:math";
// Variables should be cerated for any style that may be used my multiple components.
// Please name variables modularly.
// i.e. Generic -> Specific
// e.g. $blueBase
//      $blueShade1
//      $redBase

// COLOR
$black:            #000000;
$whiteBase:        #FFFFFF;
$whiteOpaque:      #FFFFFF40;
$whiteOpaque2:     #FAFAFA;
$orange:           #F9AD3D;
$opacityOrange:    #F5A62350;
$orangeHover:      #FCD69E;
$orangeDisable:    #FCD69E;
$grey:             #B3B3B3;
$grey1:            #CCCCCC;
$grey2:            #4E4D49;
$greyOpaque:       #B3B3B355;
$darkGrey:         #4A4A4A;
$darkGreyOpaque:   #4A4A4A7F;
$mediumGrey:       #777777;
$mediumGrey2:      #6F6F6F;
$mediumGrey3:      #666666;
$mediumGrey4:      #363636;
$mediumGrey5:      #979797;
$mediumGrey6:      #808080;
$mediumGrey7:      #8E8E8E;
$mediumGrey8:      #292929;
$mediumGrey9:      #585858;
$mediumGrey10:     #444444;
$mediumGrey11:     #131313;
$mediumGreyOpaque: #6F6F6F99;
$lightGreyDetail:  #EAEAEA;
$lightGrey:        #F6F6F6;
$lightGrey1:       #F5F5F5;
$lightGrey2:       #E6E6E6;
$lightGrey3:       #D9D9D9;
$lightGreyOpaque:  #F6F6F680;
$lightGreyOpaque2: rgba(170, 170, 170, 0.65);
$lightGreyOpaque3: rgba(124, 124, 124, 0.5);

$steelBlue:        #337BB9;
$blue:             #1875F0;
$blueShade1:       #84D3E7;
$blueShade2:       #7DD1E6;
$blueShade3:       #B9E5F0;
$blueHover:        #C1E9F3;
$blueDisable:      #C1E9F3;

$lightOrange:      #FFF7E9;
$greyStripes:      #D7D0C5;

$greyIcon:         #D8D8D8;
$greyIconOpaque:   #D8D8D87F;
$greyText:         #575757;
$green:            #34CB49;
$greenHover:       #c1edc4;
$greenDisable:     #c1edc4;
$greenLight:       #F3FFF7;

$red:              #F74236;
$redLight:         #FFF7F7;
$mediumRed:        #FF7F7F;
$opacityRed:       rgba(255, 0, 0, 0.5);

// BOX SHADOW
$boxShadow1: none;
$boxShadow2: inset -1px 2px 6px 0px rgba(0, 0, 0, 0.25);
$boxShadow3: 2px 2px 16px 1px rgba(13, 13, 13, 0.06);
$boxShadow4: 0 0 12px 0 rgba(0, 0, 0, 0.04);
$boxShadow5: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
$boxShadow6: 0 5px 11px 0 rgba(227, 227, 227, 0.5);
$boxShadow7: inset 0 0 10px 10px $lightGreyDetail;
$boxShadow8: inset 0 0 10px 10px $whiteBase;
$boxShadow9: 0 2px 5px 0 rgba(54, 54, 54, 0.05);
$boxShadow10: 0px 0px 12px 0px rgba(54, 54, 54, 0.04);
$boxShadow11: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
$boxShadow12: 0 2px 19px 0 rgba(0, 0, 0, 0.05);
$boxShadow13: inset 0 0 10px 10px $darkGreyOpaque;
$boxShadow14: inset 0 0 10px 10px $mediumGrey8 !important;
$boxShadow15: inset 0 0 10px 10px $greyIcon;

// FONT-FAMILY
$fontFamily:                'Roboto', Arial, sans-serif;
$fontFamilyArtboardBasic:   Arial, Helvetica, sans-serif; // Basic font for all authoring content

// FONT-SIZE
// Scale any font size in range of 8px
// Min viewport width - 1280px, max - 1920px
// Base Formula:
// fontSize = minFontSize + (100vw - minViewportWidth) * multiplier
// multiplier = fontScaleRange * (maxViewportWidth - minViewportWidth)
// NOTES:
// - px values in comments are provided for 1440 viewport width
// - 14px is base font as stated in style guide
// - 16px is html font as it provides better multiplication factor
$fontSizeD3:    8px;  // calc(8px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});    // 8px;
$fontSizeD2_5:  9px;
$fontSizeD2:    10px; // calc(10px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 10px
$fontSizeD1_5:  11px;// calc(11px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 11px
$fontSizeD1:    12px;// calc(12px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 12px
$fontSizeD0:    13px;// calc(12px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 12px
$fontSizeBase:  14px;// calc(14px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 14px
$fontSizeU1_5:  15px;// calc(15px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 15px
$fontSizeU1:    16px;// calc(16px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 16px
$fontSizeU2:    18px;// calc(18px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 18px
$fontSizeU3:    20px;// calc(20px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 20px
$fontSizeU4:    22px;// calc(22px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 22px
$fontSizeU5:    28px;// calc(28px + (100vw - #{$baseViewportWidth}) / #{$fontMultiplier});   // 28px

// fixed font size
$fontSize18: 18px; // it has to be a fixed value for the text component

// FONT WEIGHT
$fontWeightLight:   300;
$fontWeightRegular: 400;
$fontWeightMedium:  500;
$fontWeightBold:    700;
$fontWeightBlack:   900;

// SIZE
$assetHeaderPadding:        0 20px;
$assetsListHeaderHeight:    70px;
$assetPanelWidth:           350px;
$baseViewportWidth:         1440px;
$defaultCellHeight:         200px;
$filterHeight:              325px;
$fontMultiplier:            math.div(1920px - $baseViewportWidth, 21px - 16px);
$ProjectScreenHeaderHeight: 60px;
$screensPanelWidth:         19.5rem;
$screensPanelHiddenWidth:   60px;
$screensPanelLeftSpacing:   6rem;
//constant SCROLL_WIDTH from src\const\index.ts depends from this scss variable
$scrollBarWidth:            18px;
$scrollBarHeight:           18px;
//constant ACTIVE_CELL_OUTLINE_WIDTH from src\const\index.ts depends from this scss variable
$activeCellOutlineWidth:    1px;
//constant CELL_OUTLINE_WIDTH from src\const\index.ts depends from this scss variable
$cellOutlineWidth:          1px;
$toolbarHeight:             60px;
$imageSize:                 90px;
$contentWrapperTopPadding: 6em;
$contentWrapperBottomPadding: 6em;

$borderTiny:   1px;
$borderSmall:  2px;
$borderMedium: 4px;

$borderRadiusCircle: 50%;

// Z-INDEX
$zIndexOverlay: 9999;
$zIndexTooltip: 2000;
$zIndexModalWindow: 1000;
$zIndexArtboardPreview: 100;
$zIndexArtboardIndicator: 50;
$zIndexDragLayer: 5; // Needs to be on top of all elements except overlay
$zIndexToolbar: 4; // Needs to be on top of LayoutActions(AssetActions) and CellActions (and on top of image in time of editing)
$zIndexSaveReusableLayout: 4;
$zIndexAssetsPanel: 3;
$zIndexScreenPanel: 3;
$zIndexDragHotspot: 3;
$zIndexEditImageMode: 3;
$zIndexLayoutBottomActions: 3; // Needs to be on top of LayoutActions(AssetActions) and CellActions during image edit mode
$zIndexTranslationControl: 4;
$zIndexColumnResizer: 2;
$zIndexHeaderControl: 2;
$zIndexActiveCellAction: 2; // Needs to be on top of LayoutActions(AssetActions) while active
$zIndexSectionOverflow: 2;
$zIndexLayoutHint: 2;
$zIndexArtboardPreviewHeader: 1;
$zIndexOverlayWelcomeScreen: 1;
$zIndexSectionHint: 1;

// OPACITY
$dragPreviewOpacity: 0.75;

$actionIconSize: 20px;
$actionsBorderSize: 1px;
$actionsBorderOffset: 20px;

// MIXIN
@mixin textOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin textOverflowMultiLines($number: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
}

@mixin selectListChevron($top, $right, $bottom: auto, $left: auto) {
  &::after {
    content: '';
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    z-index: 0;
    display: inline-block;
    border: solid $darkGrey;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg);
  }
}

@mixin invisibleScrollbar {
  ::-webkit-scrollbar {
    display: none;
  }
}

// TODO: revise mixin and all places where it's used
@mixin scrollbar(
  $borderLeft: $borderSmall solid $lightGrey,
  $boxShadow: $boxShadow7,
  $scrollbarWidth: $scrollBarWidth,
  $scrollbarBGColor: transparent,
  $scrollbarThumbBorder: 6px solid transparent,
  $scrollbarThumbBorderRadius: 10px,
  $scrollbarThumbBGColor: transparent,
) {
  ::-webkit-scrollbar {
    width: $scrollbarWidth;
    height: $scrollBarHeight;
    border-left: $borderLeft;
    background-color: $scrollbarBGColor;
  }

  ::-webkit-scrollbar-thumb {
    border: $scrollbarThumbBorder;
    border-radius: $scrollbarThumbBorderRadius;
    box-shadow: $boxShadow;
    background-color: $scrollbarThumbBGColor;
  }
}

// scrollbar mixin adds styling for children instead of the actual element
// scrollbar should be replaced with scrollbar2
// because of the implementation of scrollbar we have to use important flag here
// otherwise it will be tedious to override scrolbar styling for elements inside artboard (AbbreviationIndicator is an example)
@mixin scrollbar2(
  $borderLeft: $borderSmall solid $lightGrey,
  $boxShadow: $boxShadow7,
  $scrollbarWidth: $scrollBarWidth,
  $scrollbarBGColor: transparent,
  $scrollbarThumbBorder: 6px solid transparent,
  $scrollbarThumbBorderRadius: 10px,
  $scrollbarThumbBGColor: transparent,
  $important: false,
) {
  @if $important == false {
    &::-webkit-scrollbar {
      width: $scrollbarWidth;
      height: $scrollBarHeight;
      border-left: $borderLeft;
      background-color: $scrollbarBGColor;
    }

    &::-webkit-scrollbar-thumb {
      border: $scrollbarThumbBorder;
      border-radius: $scrollbarThumbBorderRadius;
      box-shadow: $boxShadow;
      background-color: $scrollbarThumbBGColor;
    }
  }

  @else {
    &::-webkit-scrollbar {
      width: $scrollbarWidth !important;
      height: $scrollBarHeight !important;
      border-left: $borderLeft !important;
      background-color: $scrollbarBGColor !important;
    }

    &::-webkit-scrollbar-thumb {
      border: $scrollbarThumbBorder !important;
      border-radius: $scrollbarThumbBorderRadius !important;
      box-shadow: $boxShadow !important;
      background-color: $scrollbarThumbBGColor !important;
    }
  }
}

@mixin linebreaks {
  // word-wrap: break-word is a legacy browsers fallback, the same as overflow-wrap: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

@mixin hint ($bgColor: $lightGrey2, $outlineColor: $grey, $left: 0, $right: auto, $top: -21px) {
  position: absolute;
  top: $top;
  right: $right;
  left: $left;
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 15px;
  font-size: $fontSizeD1;
  color: $grey2;
  background-color: $bgColor;
  outline: 1px solid $outlineColor;
}

@mixin checkbox ($width: 8px, $secondWidth: 17px, $top: 17px, $left: 10px, $top2: 14px, $left2: 14px) {
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: $top;
    left: $left;
    width: $width;
    height: 2px;
    background-color: $orange;
    transform: rotate(45deg);
  }

  &::after {
    top: $top2;
    left: $left2;
    width: $secondWidth;
    transform: rotate(-45deg);
  }
}

@mixin tooltip {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  display: block;
  border: 1px solid $mediumGrey10;
  padding: 8px 10px;
  font-family: $fontFamily;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: $grey;
  background: $black;
  transform: translate(-50%, -5px);
  white-space: pre;
}

@mixin dropdownTitle {
  margin-bottom: 10px;
  font-family: $fontFamily;
  font-size: $fontSizeD1;
  font-weight: $fontWeightBlack;
  text-transform: uppercase;
}

@mixin separator {
  content: '';
  position: relative;
  top: 4px;
  display: inline-block;
  width: 1px;
  height: 15px;
  margin: 0 10px;
  background-color: $mediumGrey;
}

@mixin closeIcon {
  position: relative;
  top: -10px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@mixin layoutActionsBackgroundColor {
  &.backgroundColor {
    &_blue {
      background-color: $blueShade1;
    }

    &_green {
      background-color: $green;
    }
  }
}

@mixin layoutActionsBorderColor {
  &.borderColor {
    &_blue {
      border-color: $blueShade1;
    }

    &_green {
      border-color: $green;
    }
  }
}

// TODO: defined type
:export {
  contentWrapperTopPadding: $contentWrapperTopPadding;
  mediumGrey: $mediumGrey;
  ProjectScreenHeaderHeight: $ProjectScreenHeaderHeight;
}

.DragLayer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zIndexDragLayer;
  width: 100%;
  height: 100%;
  pointer-events: none;
};
