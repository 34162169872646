@mixin reset-button {
    display: block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    text-align: left;
    background: transparent;
    color: inherit;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 200ms ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:hover,
    &:focus {
        outline: none;
    }
}

.term {
    font-size: 1.2rem;

    &::after {
        content: " — ";
        white-space: pre;
        display: inline-block;
        font-weight: 100;
        opacity: 0.7;
        font-size: 1rem;
    }
}

.definition {
    font-size: 0.9rem;
}

.buttons {
    margin: .5rem 0 0;
    display: flex;

    .button {
        margin-right: .5rem;
    }
}

.button {
    @include reset-button;
    padding: .75rem;
    color: $orange;
    font-size: 1rem;
    transition: background-color 200ms ease-in-out;
    position: relative;

    &:disabled {
        opacity: 0.75;
        color: $grey;
    }

    &:hover,
    &:focus {
        background: $lightGreyOpaque3;
    }
}