.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: 0;
  padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  position: relative;
  list-style-type: square;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  position: relative;
  list-style-type: none;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  position: absolute;
  left: -36px;
  width: 30px;
  text-align: right;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  width: 30px;
  text-align: left;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(a) ". ";
  counter-increment: a;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(b, lower-alpha) ". ";
  counter-increment: b;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(c, lower-roman) ". ";
  counter-increment: c;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(d) ". ";
  counter-increment: d;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(e, lower-alpha) ". ";
  counter-increment: e;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: a;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: b;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: c;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: d;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: e;
}

// prevent appearing space before NOWRAPped text, that cause by white-space: pre-wrap, that is setting-up by Draft.js DCC-3036
.public-DraftEditor-content {
  white-space: pre-line !important;
}
