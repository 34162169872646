/**
  * This file is for global and helper classes only.
  * The best example would be a button class. However we could
  * also create an individual component for <Button /> which would
  * be more in keeping with react.
  */

// DRAFTJS EDITOR
.DraftEditor-root {
  width: 100%;
}

body {
  overflow: hidden;
}

// GLOBAL BUTTON STYLES
// Bootstrap styles
.btn {
  position: relative;
  width: 140px !important;
  height: 47px;
  border-width: 1px;
  border-style: solid;
  border-radius: 30px !important;
  padding: 0 !important;
  font-size: $fontSizeD2;
  font-weight: $fontWeightBlack;
  line-height: $fontSizeD2;
  outline: none;
  letter-spacing: 1.25px;

  &:focus {
    box-shadow: none !important;
  }

  &.btn-primary {
    border-color: $orange;
    color: $whiteBase;
    background: $orange;

    &:hover {
      border-color: #DC8B27;
      background: #DC8B27;
    }

    &:active {
      border-color: #DC8B27 !important;
      background: #DC8B27 !important;
    }

    &:disabled {
      border-color: #DC8B27;
      background: #DC8B27;
      cursor: not-allowed;
    }
  }

  &.btn-secondary {
    border-color: $grey;
    color: $mediumGrey;
    background: $whiteBase;

    &:hover {
      background: $lightGrey;
    }

    &:active {
      color: $mediumGrey !important;
      background: $lightGrey !important;
    }

    &:disabled {
      border-color: $grey;
      background: $lightGrey;
      cursor: not-allowed;
    }
  }
}

.form-check {
  margin: 0;
  padding: 0;

  .form-check-label {
    display: flex;
  }

  .form-check-input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;

    &:checked[type=radio] + label::before {
      background: $blue;
      box-shadow: 0 0 0 2px $blue;
    }
  }

  input[type=radio] + .form-check-label {
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      width: 18px;
      min-width: 18px;
      height: 18px;
      margin-top: 2px;
      border: 3px solid $whiteBase;
      border-radius: 50%;
      box-sizing: border-box;
      background: $whiteBase;
      box-shadow: 0 0 0 2px $lightGrey2;
      vertical-align: middle;
    }
  }
}

// GLOBAL MODAL STYLES
.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;

  &-content {
    justify-content: center;
    border: $borderSmall solid $lightGreyDetail;
    border-radius: 3px;
    background-color: $whiteBase;
    box-shadow: $boxShadow11;
  }

  &-dialog {
    max-width: unset;
  }

  &-backdrop {
    &.show {
      background-color: $grey;
      opacity: 0.7;
    }
  }
}
