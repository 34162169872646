.editor-container {
  position: relative;
  padding-left: 16px;
  margin-right: -16px;
  &::after {
    content: "";
    width: 1px;
    height: 80%;
    position: absolute;
    left: 3px;
    top: 20%;
    background: rgba(0, 0, 0, .1);
  }

  &::before {
    content: "lexical";
    position: absolute;
    top: 0px;
    right: -8px;
    color: rgba(0, 0, 0, .2);
    font-size: 11px;
  }

  sub, sup {
    span {
      font-size: 75%;
    }
  }
}

.editor-text-italic {
  font-style: italic;
}

.editor-paragraph {
  margin: 0;
}

.editor-mark {
  padding: 0;
  background: transparent;
  
  & > span, & > em, & > strong, & > li  {
    -webkit-backdrop-filter: invert(0.2) hue-rotate(30deg) saturate(150%);
    backdrop-filter: invert(0.2) hue-rotate(30deg) saturate(150%);
    display: inline-block;
  }
}